import { css } from 'styled-components';

export default css`
    @keyframes opacityPulse {
        0% {
            opacity: 1;
        }
        75% {
            opacity: 0.25;
        }
        100% {
            opacity: 1;
        }
    }

    animation: opacityPulse 2s infinite ease-in-out;
`;
