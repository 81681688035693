import { css } from 'styled-components';

export default css`
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
